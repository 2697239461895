<template>
  <div class="markets-page common-page">
    <Nav />
    <div class="page-wrap">
      <div class="page-top">
        <div class="page-about">
          <h1>行业市场</h1>
          <p class="tip">
            行业市场的介绍文案介绍文案介绍文案介绍文案介绍文案介绍文案。
          </p>
          <svg class="arrow" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM16.3536 16.3536C16.4015 16.3056 16.4377 16.2504 16.4621 16.1914C16.4861 16.1333 16.4996 16.0697 16.5 16.003L16.5 16V15.9995V8C16.5 7.72386 16.2761 7.5 16 7.5C15.7239 7.5 15.5 7.72386 15.5 8V14.7929L8.35355 7.64645C8.15829 7.45118 7.84171 7.45118 7.64645 7.64645C7.45118 7.84171 7.45118 8.15829 7.64645 8.35355L14.7929 15.5H8C7.72386 15.5 7.5 15.7239 7.5 16C7.5 16.2761 7.72386 16.5 8 16.5H15.9995H16C16.008 16.5 16.016 16.4998 16.024 16.4994C16.0811 16.4967 16.1378 16.4842 16.1914 16.4621C16.2504 16.4377 16.3056 16.4015 16.3536 16.3536Z"
              fill="white" fill-opacity="0.7" />
          </svg>
        </div>
      </div>
      <div class="card-wrap common-card-wrap">
        <div class="card-list">
          <div class="card" v-for="item in cardList" :key="item.id" @click="goDetail(item.uuid)">
            <img :src="item.cover_image" alt="" />
            <div class="about">
              <div class="about-top">
                <span class="date">{{ formatDate(item.create_time) }}</span>
              </div>
              <div class="brief">
                {{ item.name }}
              </div>
              <div class="begin-text">
                {{ item.summary }}
              </div>
              <div class="href-text">
                了解更多
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Nav from "../../component/nav.vue";
import request from '../../utils/request';
import dayjs from 'dayjs';
export default {
  name: "Products",
  components: {
    Nav,
  },
  data() {
    return {
      cardList: [],
    };
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format('YYYY.MM.DD');
    },
    goDetail(id) {
      this.$router.push({ name: 'marketsDetail', query: { id: id } })
    },
    fetchList() {
      request.get('/industry_market/list', {
        page_size: 10,
        page_num: 1
      }) // 假设接口地址为 /news
        .then(response => {
          this.cardList = response.data; // 将接口返回的数据赋值给newsList
        })
        .catch(error => {
          console.error('Error fetching news list:', error);
        });
    }
  },
  created() {
    this.fetchList()
  }
};
</script>
<style lang="less" scoped>
.markets-page {
  .page-wrap {
    position: relative;
    padding-top: 96px;
    width: 100%;
  }

  .page-top {
    padding: 0 32px;

    h1 {
      margin-bottom: 8px;
      color: #212027;
      font-size: 40px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%;
      /* 48px */
    }

    .tip {
      margin-bottom: 8px;
      color: #505054;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      /* 22.4px */
      letter-spacing: -0.28px;
    }

    .arrow {
      margin-bottom: 32px;
    }
  }

  .card-wrap {
    padding: 40px 0 80px 0;
    width: 100%;
    min-height: calc(100vh - 260px);

    .card-list {
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0 20px;
      width: calc(100% - 40px);

      .card {
        margin-bottom: 16px;
        border: 1px solid #fff;
        width: 160px;
        border-radius: 24px;
        overflow: hidden;
        background: #fff;
        box-shadow: 0px 0px 16px 0px #d7d9dc;
      }

      img {
        display: block;
        width: 160px;
        height: 160px;
        object-fit: cover;
      }

      .about {
        padding: 10px 10px 12px 10px;
      }

      .about-top {
        margin-bottom: 8px;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .tag {
          width: 32px;
          height: 16px;
          background: #ffcc00;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          color: #212027;
          border-radius: 4px;
        }

        .date {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #a9a9ac;
        }
      }

      .brief {
        font-size: 16px;
        line-height: 22px;
        color: #212027;
        font-weight: 600;
        word-break: break-all;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .href-text {
        padding-top: 8px;
        color: #fabf00;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        /* 19.2px */
      }

      .begin-text {
        padding-top: 8px;
        color: #505054;
        /* 内文 常规 */
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        /* 22.4px */
        word-break: break-all;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}</style>
